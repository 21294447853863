import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Scanner } from '@yudiel/react-qr-scanner';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface Member {
  id: number;
  name: string;
  qrCode: string;
  memberType: string;
  points: number;
  pointsToReward: number;
  rewardType: string;
  // Add other fiedls as needed
}

const CodeInput: React.FC = () => {
  const [qrCode, setQrCode] = useState('');
  const [scanning, setScanning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleScan = (data: any | null) => {
    if (data) {
      fetchMemberData(data[0].rawValue);
    }
  };

  const handleError = (err: any) => {
    alert("An error occurred, check the console for further details.")
    console.log(err);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    fetchMemberData(qrCode);
  };

  const toggleScanner = () => {
    setScanning(!scanning);
    setError(null);
  };

  const fetchMemberData = async (qrCode: string) => {
    setLoading(true);
    setError(null);
    try {
      // TODO: Pull URL value from a .env file
      const response = await fetch('https://trullino-api.vigortec.org/members/');

      if (!response.ok) {
        throw new Error('Failed to fetch member data');
      }
      const members: Member[] = await response.json();
      console.log('QRCode for query: ', qrCode);
      const foundMember = members.find(member => member.qrCode === qrCode);

      if (foundMember) {
        navigate('/dashboard', { state: { memberData: foundMember } });
      } else {
        setError("No member found with the given QR code. Please try again.");
      }
    } catch (err) {
      setError("Failed to fetch member data. Please try again.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="qr-section">
      {scanning ? (
        <div className='qr-scanner'>
          <Scanner
            scanDelay={500}
            onError={handleError}
            onScan={handleScan}
          />
          <button className="scan-qr" onClick={toggleScanner}>
            Cancel Scan
          </button>
        </div>
      ) : (
        <div className="qr-box">
          <button className="scan-qr" onClick={toggleScanner}>
            Scan your QR code
            <QrCodeScannerIcon className="qr-icon" />
          </button>
          <div className="or-divider">or</div>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              value={qrCode}
              onChange={(e) => setQrCode(e.target.value)}
              placeholder="Type in your QR code"
            />
          </form>
        </div>
      )}
      {!scanning && (
        <button
          type="submit"
          className="check-progress"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Check Progress'}
          <ArrowForwardIcon className="arrow-icon" />
        </button>
      )}
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default CodeInput;
