import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Alert, AlertTitle } from '@mui/material';

interface MemberData {
  id: number;
  name: string;
  memberType: string;
  points: number;
  pointsToReward: number;
  rewardType: string;
  // Add other fields as needed
}

const PointsDashboard: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const memberData = (location.state as { memberData: MemberData })?.memberData;

  const handleReset = () => {
    navigate('/');
  };

  // Error handling
  console.log('Member Data',
    '\nname:', memberData.name,
    '\nmemberType:', memberData.memberType,
    '\npointsToReward:', memberData.pointsToReward,
    '\nrewardType:', memberData.rewardType);
  if (!memberData) {  // This should never happen
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        No member data available. Please scan or input your QR code again.
      </Alert>
    );
  }
  if (
    !memberData.name ||
    !memberData.memberType ||
    !memberData.pointsToReward ||
    !memberData.rewardType
  ) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        Member was found, but response did not contain expected values.
      </Alert>
    );
  }

  return (
    <div>
      <div className="points-dashboard">
        <h3>Hey {memberData.name}, you're on track for your Trullino reward!</h3>
        <h4>Member type:</h4>
        <div className="member-type">{memberData.memberType}</div>
        <div className="points-box">
          <strong>Points: {memberData.points}</strong>
        </div>
        {memberData.pointsToReward > 0 ? (
          <div className="points-left">Points left till reward: {memberData.pointsToReward}</div>
        ) : (
          <div className="reward-message">Congrats you've got a reward: {memberData.rewardType}</div>
        )}
      </div>
      <h5>Something not right? Pop into Trullino and ask a member of our staff.</h5>
      <button onClick={handleReset} className="reset-btn">Reset</button>
    </div>
  );
};

export default PointsDashboard;
