import React from 'react';

const Header: React.FC = () => {
  return (
    <header>
      <img src="/logo.png" alt="Trullino Logo" />
      <h1>Points Tracker</h1>
      <h2>Check the progress of your Trullino points for the Members and Partner Passes.</h2>
    </header>
  );
};

export default Header;
